import React, { memo, useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeGetDataAccount } from "../../../lib/selector";
import _ from "lodash";
import { setDataAccount, setToast } from "../../client/actions";
import { handleApiErrors } from "../../../lib/api-errors";
import { ReactComponent as IconWarning } from "../../../assets/img/icon/ic-warning1.svg";
import {
  formatDate,
  getMsgByErrorCode,
  removeAccent,
  StringToInt,
} from "../../../util";
import { translate } from "react-i18next";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function FormSignCont(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handlePreSubStep,
    handleSubmit,
    submitting,
    dataAccount,
    orcData,
    openAccount,
    handleGetSid,
    t,
  } = props;

  const [data, setData] = useState();
  const [dataFill, setDataFill] = useState();
  const [templateId, setTemplateId] = useState();
  const [alias, setAlias] = useState();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTemplate();

    const handleMessage = (event) => {
      if (event?.data) {
        if (/^\s*(\{|\[)/.test(event?.data)) {
          try {
            const tmp = JSON.parse(event.data);
            console.log("tmp", tmp, event.data);

            if (StringToInt(tmp?.code) === 0) openAccount();
            else if (tmp?.code && StringToInt(tmp?.code) !== 0) {
              _handleToast(getMsgByErrorCode(tmp?.code), "error");
            }
          } catch (e) {
            return;
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!data || !templateId || !alias) return;
    createContract();
  }, [data, templateId, alias]);

  useEffect(() => {
    if (!dataFill || !dataAccount) return;
    updateFillData({
      FullName: dataAccount?.C_FULL_NAME,
      Year: formatDate(new Date(), "/", "dmy").split("/")[2] + "",
      Month: formatDate(new Date(), "/", "dmy").split("/")[1] + "",
      Date: formatDate(new Date(), "/", "dmy").split("/")[0] + "",
      DateOfBirth: dataAccount?.C_CUST_BIRTH_DAY,
      IdCardNumber: dataAccount?.C_CARD_ID,
      IssuedDate: dataAccount?.C_ISSUE_DATE,
      IssuedPlace: dataAccount?.C_ISSUE_PLACE,
      PermanentAddress: dataAccount?.C_ADDRESS,
      CurrentAddress: dataAccount?.C_CONTACT_ADDRESS,
      PhoneNumber: dataAccount?.C_MOBILE,
      InvestmentGoalShort: dataAccount?.INVEST_SHORT === "0" ? "x" : "",
      RiskToleranceLow: dataAccount?.RISK_LOW === "0" ? "x" : "",
      InvestmentExperienceNone: dataAccount?.INVEST_NONE === "0" ? "x" : "",
      InvestmentKnowledgeNo: dataAccount?.INVEST_NO === "0" ? "x" : "",
      InvestmentGoalMedium: dataAccount?.INVEST_SHORT === "1" ? "x" : "",
      RiskToleranceAverage: dataAccount?.RISK_LOW === "1" ? "x" : "",
      InvestmentExperienceStock: dataAccount?.INVEST_NONE === "1" ? "x" : "",
      InvestmentKnowledgeLimited: dataAccount?.INVEST_NO === "1" ? "x" : "",
      InvestmentGoalLong: dataAccount?.INVEST_SHORT === "2" ? "x" : "",
      RiskToleranceHigh: dataAccount?.RISK_LOW === "2" ? "x" : "",
      InvestmentExperienceBond: dataAccount?.INVEST_NONE === "2" ? "x" : "",
      InvestmentKnowledgeModerate: dataAccount?.INVEST_NO === "2" ? "x" : "",
      InvestmentGoalOther: dataAccount?.INVEST_SHORT === "3" ? "x" : "",
      InvestmentExperienceFundCertificate:
        dataAccount?.INVEST_NONE === "3" ? "x" : "",
      InvestmentKnowledgeGood: dataAccount?.INVEST_NO === "3" ? "x" : "",
      IsEquityYes: "x",
      IsSecuritiesDepositoryYes: "x",
      IsMarginYes: dataAccount?.C_ACCOUNT_TYPE === "1" ? "x" : "",
      IsDerivativesYes: "",
      IsEquityNo: "",
      IsSecuritiesDepositoryNo: "",
      IsMarginNo: dataAccount?.C_ACCOUNT_TYPE !== "1" ? "x" : "",
      IsDerivativesNo: "x",
      DichvugiaodichCo: dataAccount?.C_SALE_ID ? "x" : "",
      GiaodichtructuyenCo: dataAccount?.C_GDTT === "1" ? "x" : "",
      GiaodichTongdaiCo: dataAccount?.C_GDTD === "1" ? "x" : "",
      DichvugiaodichKhong: dataAccount?.C_SALE_ID ? "" : "x",
      GiaodichtructuyenKhong: dataAccount?.C_GDTT !== "1" ? "x" : "",
      GiaodichTongdaiKhong: dataAccount?.C_GDTD !== "1" ? "x" : "",
      UngtruoctienCo: dataAccount?.C_UTB === "1" ? "x" : "",
      NhanthongtinEmailCo: dataAccount?.C_GDE === "1" ? "x" : "",
      NhanSMSCo: dataAccount?.C_DKSMS === "1" ? "x" : "",
      UngtruoctienKhong: dataAccount?.C_UTB !== "1" ? "x" : "",
      NhanthongtinEmailKhong: dataAccount?.C_GDE !== "1" ? "x" : "",
      NhanSMSKhong: dataAccount?.C_DKSMS !== "1" ? "x" : "",
      Password: dataAccount?.C_MOBILE_TRADING_PASSWORD,
      AccName1:
        dataAccount?.C_DKTK === "1" && dataAccount?.C_FULL_NAME
          ? removeAccent(dataAccount?.C_FULL_NAME.toUpperCase())
          : "",
      AccNumber1: dataAccount?.C_BANK_ACCOUNT,
      Bank1: dataAccount?.C_BANK_NAME,
      Fatca1: dataAccount?.QUEST_CHECK_NOT_USA ? "x" : "",
      Fatca2: dataAccount?.CHECK_USA ? "x" : "",
      Fatca3: dataAccount?.CHECK_USA_BUT ? "x" : "",
      refId: dataAccount?.REF_ID,
      AccName2: "",
      AccNumber2: "",
      Bank2: "",
      STT2: "",
      // envDate: formatDate(new Date(), "/", "ymd"),
      name_recipient: dataAccount?.C_FULL_NAME,
      mail_recipient: dataAccount?.C_EMAIL,
      A1: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(0),
      A2: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(1),
      A3: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(2),
      A4: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(3),
      A5: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(4),
      A6: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(5),
      B1: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(0),
      B2: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(1),
      B3: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(2),
      B4: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(3),
      B5: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(4),
      B6: dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT.charAt(5),
      C1:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(0),
      C2:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(1),
      C3:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(2),
      C4:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(3),
      C5:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(4),
      C6:
        dataAccount?.C_ACCOUNT && dataAccount?.C_ACCOUNT_TYPE === "0"
          ? ""
          : dataAccount?.C_ACCOUNT.charAt(5),
      envno:
        dataAccount?.C_ACCOUNT +
        "/" +
        formatDate(new Date(), "/", "dmy").split("/")[2] +
        "",
      male: dataAccount?.C_GENDER === "NAM" ? "x" : "",
      female: dataAccount?.C_GENDER === "NAM" ? "" : "x",
      email1: dataAccount?.C_EMAIL,
      email: dataAccount?.C_EMAIL,
      mobile1: dataAccount?.C_MOBILE,
      STK: "059C" + dataAccount?.C_ACCOUNT + "",
      Password1:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      Password2:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      Password3:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      Password4:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      Password5:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      Password6:
        dataAccount?.C_MOBILE_TRADING_PASSWORD && dataAccount?.C_GDTD !== "1"
          ? ""
          : "*",
      DKGDCK: dataAccount?.C_ACCOUNT_TYPE === "0" ? "x" : "",
      DKGDCKKQ: dataAccount?.C_ACCOUNT_TYPE === "1" ? "x" : "",
      nationality: dataAccount?.C_NATIONALITY,

      p_001_r_001_mail_recipient: "cskh@upstock.com.vn",
      p_001_r_001_name_recipient: "",
      p_001_r_001_phone_recipient: "",
      p_001_r_001_contact_recipient: "",

      p_002_r_001_name_recipient: dataAccount?.C_FULL_NAME,
      p_002_r_001_mail_recipient: dataAccount?.C_EMAIL,
      p_002_r_001_phone_recipient: dataAccount?.C_MOBILE,
      p_002_r_001_contact_recipient: dataAccount?.C_CARD_ID,
    });
  }, [dataFill]);

  const updateFillData = (nameValueMap) => {
    const updatedData = dataFill.map((item) => {
      const specialKey = `${item.id}_${item.name}`;
      if (nameValueMap[specialKey] !== undefined) {
        return { ...item, value: nameValueMap[specialKey] };
      }
      if (nameValueMap[item.name] !== undefined) {
        return { ...item, value: nameValueMap[item.name] };
      }
      return item;
    });
    setData(updatedData);
  };

  const getTemplate = () => {
    setLoading(true);
    const request = fetch(
      `${apiUrl}/getTemplate/${
        dataAccount?.C_ACCOUNT_TYPE === "0" ? "HDMTK2" : "HDMTKKQ"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        // body: JSON.stringify(params),
        signal: abortController.signal,
      }
    );
    handleApiGetTeamplate(request);
  };

  const handleApiGetTeamplate = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setDataFill(json?.data?.datas[0]);
          setTemplateId(json?.data?.templateId);
          setAlias(json?.data?.alias);
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  const createContract = () => {
    const params = {
      sid: dataAccount?.REF_ID,
      anhMatTruoc: dataAccount?.C_ANH_MAT_TRUOC,
      anhMatSau: dataAccount?.C_ANH_MAT_SAU,
      email: dataAccount?.C_EMAIL,
      telephoneNumber: dataAccount?.C_MOBILE,
      personalName: dataAccount?.C_FULL_NAME,
      location: dataAccount?.C_CONTACT_ADDRESS,
      stateOrProvince: "",
      country: orcData?.data?.nationality,
      personalID: dataAccount?.C_CARD_ID,
      provideAddress: dataAccount?.C_ISSUE_PLACE,
      provideDate: dataAccount?.C_ISSUE_DATE,
      commune: "",
      templateId: templateId,
      alias: alias,
      datas: [data],
    };

    const request = fetch(`${apiUrl}/createContract`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiCreateContract(request);
  };

  const handleApiCreateContract = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        if (json.iRs === 1 && json?.data.code === "0") {
          setUrl(json?.data?.response?.urlIndividual);
        } else {
          _handleToast(json?.data?.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false);

        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  function handleClickTD() {
    const BM02 =
      "https://www.irs.com.vn/sites/default/files/M%E1%BA%ABu%20H%E1%BB%A3p%20%C4%91%E1%BB%93ng%20HD_BM02.IRS%2816-08-2024%29%20%28IRS%29_0.pdf";
    const BM03 =
      "https://www.irs.com.vn/sites/default/files/M%E1%BA%ABu%20H%E1%BB%A3p%20%C4%91%E1%BB%93ng%20HD_BM02_03.IRS%2816-08-2024%29%20%28IRS%29_0.pdf";

    if (dataAccount?.C_ACCOUNT_TYPE === "0") {
      window.open(BM02, "_blank", "noopener,noreferrer");
    } else if (dataAccount?.C_ACCOUNT_TYPE === "1") {
      window.open(BM03, "_blank", "noopener,noreferrer");
    }
  }

  return (
    <form className="flex flex-column gap-4 form-group">
      <div
        style={{
          paddingLeft: "23px",
        }}
      >
        <span className="text-sm font-semibold text-skin-title">
          {t("agreement-signature")}
        </span>
        <ul
          className="list-square"
          style={{
            paddingLeft: "26px",
          }}
        >
          <li className="text-xs text-skin-body">{t("terms-acceptance")}</li>
          <li className="text-xs text-skin-body">
            {t("view-terms")}{" "}
            <span
              className="text-skin-green underline cursor-pointer"
              onClick={() => handleClickTD()}
            >
              {t("here")}
            </span>
          </li>
        </ul>
      </div>

      <div className="flex flex-column gap-4 col-span-6">
        <div className="flex flex-column gap-3">
          <div
            style={{
              height: `calc(100vh - 150px)`,
              userSelect: "none",
            }}
            className={`col-span-6 relative rounded-xl bg-skin-sur-secondary cursor-pointer flex flex-column items-center justify-center border border-dashed border-skin-weak select-none w-100`}
          >
            {url ? (
              <iframe
                src={url}
                title="External Content"
                className="iframe-cus w-100 h-100 border-0 rounded-xl"
              />
            ) : loading ? (
              <div className="loader text-lg font-semibold text-skin-title text-center"></div>
            ) : !loading ? (
              <div className="flex flex-column gap-2 items-center justify-center">
                <IconWarning />
                <span className="text-lg font-semibold text-skin-title text-center">
                  {t("processing-error")}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-span-5 flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2">
          <button className="btn-close" onClick={() => handlePreSubStep()}>
            {t("pre")}
          </button>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormSignCont)
);
