import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import {
  makeGetDataAccount,
  makeGetListAcc,
  makeGetListAccHold,
} from "../../../lib/selector";
import RenderAccount from "../../../components/input/renderAccount";
import RenderRadio from "../../../components/input/renderRadio";
import { handleApiErrors } from "../../../lib/api-errors";
import _ from "lodash";
import { setSaveListAccHold, setToast } from "../../client/actions";
import { lengthRequired } from "../../../util/validation";
import { setDataAccount } from "../../client/actions";
import { ReactComponent as IconSubClause } from "../../../assets/img/icon/ic-subClause.svg";
import { translate } from "react-i18next";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormSelectAccount(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handleSubmit,
    handleNextSubStep,
    handlePreSubStep,
    formAccType,
    dataAccount,
    submitting,
    prePageFirst,
    t,
    listAcc,
    listAccHold,
  } = props;

  const [randAcc, setRandAcc] = useState(); // Lưu acc random

  const preRandAcc = usePrevious(randAcc);

  useEffect(() => {
    initForm();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (listAcc && !!listAcc?.length) {
      if (dataAccount?.C_ACCOUNT) {
        setRandAcc(dataAccount?.C_ACCOUNT);
      } else setRandAcc(listAcc[0]?.C_ACCOUNT_CODE);
    }
  }, [listAcc, dataAccount]);

  useEffect(() => {
    if (randAcc && !_.isEqual(randAcc, preRandAcc)) {
      dispatch(change("formResStep12", "formAccount", randAcc));
    }
  }, [randAcc]);

  const initForm = () => {
    dispatch(change("formResStep12", "formAccNum", "0"));

    if (dataAccount?.C_ACCOUNT_TYPE) {
      dispatch(
        change("formResStep12", "formAccType", dataAccount?.C_ACCOUNT_TYPE)
      );
    } else {
      dispatch(change("formResStep12", "formAccType", "1"));
    }
  };

  const getHoldAcc = (values) => {
    const params = {
      user: "back",
      cmd: "LOCK_ACCOUNTCODE",
      param: {
        ACCOUNT_CODE: values.formAccount + "",
      },
      sid: dataAccount.S_ID,
    };

    let _dataAcc = Object.assign({}, dataAccount);
    _dataAcc.C_ACCOUNT = values.formAccount;
    _dataAcc.C_ACCOUNT_TYPE = values.formAccType;

    dispatch(setDataAccount(_dataAcc));

    dispatch(setSaveListAccHold([...listAccHold, values.formAccount]));

    const request = fetch(`${apiUrl}/core`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiGetHoldAcc(request);
  };

  const handleApiGetHoldAcc = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          handleNextSubStep();
        } else {
          _handleToast(json.sRs);
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  const handleChangeAccType = (type) => {
    if (type === "T") {
      dispatch(change("formResStep12", "formAccType", "0"));
    } else if (type === "TQ") {
      dispatch(change("formResStep12", "formAccType", "1"));
    }
  };

  const _handlePreSubStep = () => {
    dispatch(setSaveListAccHold([]));

    handlePreSubStep();
  };

  function submit(values) {
    if (
      values.formAccount !== dataAccount?.C_ACCOUNT &&
      !_.find(listAccHold, (item) => item === values.formAccount)
    )
      getHoldAcc(values);
    else {
      let _dataAcc = Object.assign({}, dataAccount);
      _dataAcc.C_ACCOUNT = values.formAccount;
      _dataAcc.C_ACCOUNT_TYPE = values.formAccType;

      dispatch(setDataAccount(_dataAcc));
      handleNextSubStep();
    }
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className=" d-flex flex-column gap-r-8 form-group"
    >
      <div className="d-flex flex-row gap-8">
        <Field
          name="formAccNum"
          component={RenderRadio}
          type="radio"
          value="0"
          id="formAccNumN"
          labelName={t("acc-normal")}
        />{" "}
        <div>
          <Field
            name="formAccNum"
            component={RenderRadio}
            type="radio"
            value="1"
            id="formAccNumB"
            labelName={t("acc-special")}
            disabled
          />{" "}
        </div>
      </div>
      <div className="div-input-select">
        <span className="lable-select-acc ">059C</span>
        <Field name="formAccount" component={RenderAccount} disabled />
      </div>
      <div className="d-flex flex-column gap-3">
        <span className="text-xs font-normal text-skin-title">
          {t("acc-suggest")}
          {": "}
        </span>
        <div
          className="grid grid-cols-5 gap-2"
          style={{
            width: "50%",
            minWidth: "375px",
          }}
        >
          {listAcc &&
            !!listAcc.length &&
            listAcc.slice(0, 5).map((item, index) => (
              <div
                key={item.C_ACCOUNT_CODE}
                className={`style-list-acc cursor-pointer rounded-3xl border border-skin-strong  text-center d-flex justify-center items-center ${
                  randAcc === item.C_ACCOUNT_CODE + ""
                    ? "bg-skin-sur-green text-skin-mint900"
                    : "text-skin-subdued"
                }`}
                onClick={() => setRandAcc(item.C_ACCOUNT_CODE + "")}
              >
                {item.C_ACCOUNT_CODE}
              </div>
            ))}
        </div>
      </div>
      <div className="grid grid-col-1 gap-2 w-100 rounded-2xl">
        <span className="text-xs font-semibold text-skin-title">
          {t("acc-type")}
        </span>
        <div className="d-flex align-items-center gap-2">
          <div
            className={`w-50 box-type-acc d-flex flex-row justify-content-between gap-4 items-center relative bg-skin-sur-secondary rounded-xl cursor-pointer ${
              formAccType === "1" ? "border border-skin-green" : " "
            }`}
            onClick={() => handleChangeAccType("TQ")}
          >
            <IconSubClause
              className={`absolute rounded-xl h-100 `}
              style={{
                top: "0",
                right: "0",
                width: "64px",
              }}
            />
            <div
              className={`d-flex flex-row align-items-center justify-content-center `}
            >
              <div>
                <Field
                  name="formAccType"
                  component={RenderRadio}
                  type="radio"
                  value="1"
                  id="formAccTypeKQ"
                />{" "}
              </div>

              <span
                className={`label-radio font-medium pb-1 ${
                  formAccType === "1" ? "label-radio_title" : " text-skin-title"
                }`}
              >
                {t("acc-tt-kq")}
              </span>
            </div>
          </div>
          <div
            className={`w-50 box-type-acc d-flex flex-row justify-content-between gap-4 items-center relative bg-skin-sur-secondary rounded-xl cursor-pointer ${
              formAccType === "0" ? "border border-skin-green" : " "
            }`}
            onClick={() => handleChangeAccType("T")}
          >
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div>
                <Field
                  name="formAccType"
                  component={RenderRadio}
                  type="radio"
                  value="0"
                  id="formAccTypeN"
                />{" "}
              </div>

              <span
                className={`label-radio font-medium pb-1 ${
                  formAccType === "0" ? "label-radio_title" : " text-skin-title"
                }`}
              >
                {t("acc-tt")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row-reverse justify-content-start gap-2 w-button">
        <button
          type="submit"
          disabled={submitting}
          className="success btn-submit "
        >
          {t("continue-step")}
        </button>
        <button className="btn-close " onClick={() => _handlePreSubStep()}>
          {t("pre")}
        </button>
      </div>
    </form>
  );
}

FormSelectAccount = reduxForm({
  form: "formResStep12",
  enableReinitialize: true,
})(FormSelectAccount);

const selector = formValueSelector("formResStep12");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();
  const getListAccHold = makeGetListAccHold();
  const getListAcc = makeGetListAcc();

  const { formAccount, formAccType, formAccNum } = selector(
    state,
    "formAccount",
    "formAccType",
    "formAccNum"
  );

  return {
    dataAccount: getDataAccount(state),
    listAccHold: getListAccHold(state),
    listAcc: getListAcc(state),

    formAccount,
    formAccType,
    formAccNum,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormSelectAccount)
);
